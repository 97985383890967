import React from "react"
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Helmet } from 'react-helmet'
import Banner from "../../components/compare-banner"
import List from "../../components/compare-list"
import Testimonial from '../../components/testimonial'
import ComparisonTable from "../../components/comparison-table"
import SwiperTabs from '../../components/swiper-tabs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { swiperTabsContent } from "../../lib/swiper-tabs-content"
// import { testimonialContent } from "../../lib/testimonial-content"

const bannerContent = {
  title: "Looking for a Chargebee alternative? You're in the right place.",
  text: "See why every day businesses choose Billsby - the most powerful, customizable and easy-to-integrate recurring billing software and compare Chargebee to the platform that says if you can dream it, we can bill it.",
  compLogo: require('../../images/chargebee.svg'),
  billsbyImage: require('../../images/compare-banner@2x.png'),
  compareImage: require('../../images/compare-banner2.svg'),
  svg: true,
}

const listContent = {
  bgColor: '',
  content: [
    {
      title: "Swap chaos and complexity for clarity and structure.",
      image: require('../../images/comparison-blocks.svg'),
      svg: true,
      list: [
        {
          subtitle: "Products, plans and cycles",
          text: "Compare Chargebee - where everything is a plan - to Billsby's structured approach with products, plans and cycles creating a clear hierarchy."
        },
        {
          subtitle: "Feature tags",
          text: "Need an alternative to Chargebee requiring your system to know everything about customers plans? Feature tags make access control quick and easy."
        },
        {
          subtitle: "Easy to get started",
          text: "You don't need a developer to understand Billsby. With this Chargebee alternative, you can be up and running in hours - not days or weeks."
        },
      ]
    },
    {
      title: "Pick great APIs that reduce your workload and speed up integration.",
      image: require('../../images/integration.svg'),
      svg: true,
      list: [
        {
          subtitle: "Real-time usage trackings",
          text: "We won't make you wait to the end of the month to tell us what customers have used - give us real time information and we'll keep accounts up-to-date."
        },
        {
          subtitle: "Drop-in checkout and account management",
          text: "Billsby's checkout and account management tools include custom fields - so you can do whatever data collection you need without third party tools."
        },
        {
          subtitle: "If you can do it in Billsby, you can do it with the API",
          text: "We designed Billsby so that everything you can do on the control panel can be done in our advanced API - so there are no limits to your creativity."
        },
      ]
    },
    {
      title: "Pricing that scales as your business grows - in the right direction.",
      image: require('../../images/pricing.svg'),
      svg: true,
      list: [
        {
          subtitle: "Start with a one month free trial",
          text: "Every Billsby customer starts with a one month free trial - so you can see if we're the right Chargebee alternative for you."
        },
        {
          subtitle: "Low revenue share and no setup fees",
          text: "Compare Chargebee, who increase your revenue share up as high as 0.75% as your business grows, with our low monthly cost and transparent 0.4% overage fee."
        },
        {
          subtitle: "Lower monthly fees",
          text: "An alternative to Chargebee's $99 monthly fee - at Billsby, our pricing starts from as little as $35 a month."
        },
      ]
    },
  ]
}

const listConten2 = {
  bgColor: "blue",
  content: [
    {
      title: "Get started quickly and easily, and get help when you need it.",
      image: require('../../images/comparison-doors.svg'),
      svg: true,
      list: [
        {
          subtitle: "Obsessive support",
          text: "From in-app chat to phone calls on your schedule, all the support you need is included with every Billsby plan - great service should never be an upsell."
        },
        {
          subtitle: "No complex contracts",
          text: "Unlike Chargebee, we don't hide our most advanced features behind expensive Enterprise plans. Our pricing is open, honest and clear."
        },
        {
          subtitle: "Integration support ",
          text: "Our development services team can help build out your end-to-end subscription business with custom integrations for just $25 per hour."
        },
      ]
    },
    {
      title: "Go beyond subscription management",
      bgColor: "blue",
      btnName: "COMING SOON",
      image: require('../../images/management.svg'),
      svg: true,
      list: [
        {
          subtitle: "Value score",
          text: "Truly understand your customers - get to know who your best and worst customers are and customise your experience to suit their needs."
        },
        {
          subtitle: "Plan actions",
          text: "Integrate your subscription journey with the other tools and services your business uses to support your customers effectively."
        },
        {
          subtitle: "Customer profile reporting",
          text: "Enrich your subscriber records with extra data - like how much they earn, where they live or what they do for a living."
        },
      ]
    },
  ]
}

const comparisonTableContent = {
  title: 'Bigger, better features',
  description: "Feature-by-feature, you get more options, power and customization when you choose Billsby - and there's no need to upgrade to more expensive plans to take advantage of them either.",
  heading: [
    {
      logo: 'billsby_logo_blue.png',
      title: 'Billsby' 
    },
    {
      logo: require('../../images/chargebee.svg'),
      title: 'chargify',
      brandSize: "Launch",
      svgLogo: true,
    },
    {
      logo: require('../../images/chargebee.svg'),
      title: 'chargify',
      brandSize: "Rise",
      svgLogo: true,
    },
    {
      logo: require('../../images/chargebee.svg'),
      title: 'chargify',
      brandSize: "Scale",
      svgLogo: true,
    },
  ],
  body: [
    {
      title: 'Create unlimited products, plans and cycles',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
      ]
    },
    {
      title: 'Connect unlimited payment gateways',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
      ]
    },
    {
      title: 'Have unlimited administrators',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
      ]
    },
    {
      title: 'Free trials, setup fees, minimum terms and pro-rating',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
      ]
    },
    {
      title: 'Add-ons and allowances with real time usage API',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
      ]
    },
    {
      title: 'Sell globally with unlimited currencies and tax profiles',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
      ]
    },
    {
      title: 'Advanced cancellation and retention tools',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
      ]
    },
    {
      title: 'Plan actions and value score',
      subtitle: 'COMING SOON',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
      ]
    },
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      text: 'Sign up today',
      isExternal: true
    },
    {
      scheduleButton: true,
      buttonColor: "black"
    }
  ]
}

const comparisonTableContent2 = {
  title: 'Fairer, clearer pricing',
  description: "At Billsby, on our Core plan $35/mo plus 0.4% on revenue exceeding $15,000 is all you'll pay to use Billsby to power your subscription business with all the features you need to get started and grow.",
  heading: [
    {
      logo: 'billsby_logo_blue.png',
      title: 'Billsby' 
    },
    {
      logo: require('../../images/chargebee.svg'),
      title: 'chargify',
      brandSize: "Launch",
      svgLogo: true
    },
    {
      logo: require('../../images/chargebee.svg'),
      title: 'chargify',
      brandSize: "Rise",
      svgLogo: true
    },
    {
      logo: require('../../images/chargebee.svg'),
      title: 'chargify',
      brandSize: "Scale",
      svgLogo: true
    },
  ],
  body: [
    {
      title: "Free trial",
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/>
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/>
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/>  
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/>  
        },
      ]
    },
    {
      title: "If you make $10,000 per month, you'll pay",
      content: [
        {
          itemContent: "$35" 
        },
        {
          itemContent: "$99" 
        },
        {
          itemContent: "$299" 
        },
        {
          itemContent: "$599" 
        },
      ]
    },
    {
      title: "If you make $25,000 per month, you'll pay",
      content: [
        {
          itemContent: "$75"
        },
        {
          itemContent: "$99"
        },
        {
          itemContent: "$299"
        },
        {
          itemContent: "$599"
        },
      ]
    },
    {
      title: "If you make $50,000 per month, you'll pay",
      content: [
        {
          itemContent: "$175"
        },
        {
          itemContent: "$224"
        },
        {
          itemContent: "$299"
        },
        {
          itemContent: "$599"
        },
      ]
    },
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      text: 'Sign up today',
      isExternal: true
    },
    {
      scheduleButton: true,
      buttonColor: "black"
    }
  ]
}

const Chargebee = () => (
  <Layout isLandingPage>
    <SEO 
      title="Chargebee Alternative | Billsby" 
      description="If you're looking for a Chargebee alternative to manage subscription billing and recurring payments, compare Chargebee to Billsby." 
      url="https://www.billsby.com/compare/compare-chargebee"
    />
    <div className="compare-page">
      <Banner content={bannerContent}/>
      <List compare={listContent}/>
      <Testimonial />
      <ComparisonTable content={comparisonTableContent} />
      <ComparisonTable content={comparisonTableContent2} />
      <List compare={listConten2}/>
      <SwiperTabs 
        tabs={swiperTabsContent} 
        title="The bottom line? If you can dream it, we can bill it."
        description="Every Billsby account supports unlimited products, plans and cycles - making it really easy to support even the most complex plans."
      />
    </div>
  </Layout>
)

export default Chargebee



